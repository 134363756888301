<div id="wrapper">
  <nav *ngIf="currentUser" class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
    <div class="container-fluid d-flex flex-column p-0">
      <a class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" routerLink="/">
        <img src="assets/img/logo-CNE-blanco-200.png" style="min-width: 10px;width: 110px;"/>
      </a>
      <hr class="sidebar-divider my-0">
      <ul class="nav navbar-nav text-light" id="accordionSidebar">
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/">
          <i class="fas fa-home"></i><span>Inicio</span></a></li>
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="/procesos">
          <i class="fas fa-table"></i><span>Comités</span></a></li>
        <!--<li class="nav-item"><a class="nav-link" routerLinkActive="active" href="/tramites">
          <i class="far fa-user-circle"></i><span>Solicitudes</span></a></li>
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="/configuraciones">
          <i class="far fa-user-circle"></i><span>Configuraciones</span></a></li>-->
        <li class="nav-item"><a class="nav-link" href="#" (click)="logout()">
          <i class="far fa-user-circle"></i><span>Salir</span></a></li>
      </ul>
      <div class="text-center d-none d-md-inline">
        <button class="btn rounded-circle border-0" id="sidebarToggle" type="button"></button>
      </div>
    </div>
  </nav>
  <div class="d-flex flex-column" id="content-wrapper">
    <div id="content">
      <nav *ngIf="currentUser" class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
        <div class="container-fluid">
          <button class="btn btn-link d-md-none rounded-circle mr-3" id="sidebarToggleTop" type="button">
            <i class="fas fa-bars"></i>
          </button>
          <form class="form-inline d-none d-sm-inline-block mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
            <div class="input-group">
              <input class="bg-light form-control border-0 small" type="text" placeholder="Buscar ...">
              <div class="input-group-append">
                <button class="btn btn-primary py-0" type="button"><i class="fas fa-search"></i></button>
              </div>
            </div>
          </form>
          <ul class="nav navbar-nav flex-nowrap ml-auto">
            <li class="nav-item dropdown d-sm-none no-arrow">
              <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">
                <i class="fas fa-search"></i></a>
              <div class="dropdown-menu dropdown-menu-right p-3 animated--grow-in" aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto navbar-search w-100">
                  <div class="input-group">
                    <input class="bg-light form-control border-0 small" type="text" placeholder="Buscar ...">
                    <div class="input-group-append">
                      <button class="btn btn-primary py-0" type="button"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                </form>
              </div>
            </li>
            <div class="d-none d-sm-block topbar-divider"></div>
            <li class="nav-item dropdown no-arrow">
              <div class="nav-item dropdown no-arrow">
                <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">
                  <span class="d-none d-lg-inline mr-2 text-gray-600 small">{{currentUser.name}}</span>
                  <img class="border rounded-circle img-profile" src="assets/img/user.png"></a>
                <div class="dropdown-menu shadow dropdown-menu-right animated--grow-in">
                  <!--<a class="dropdown-item" href="#">
                    <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Perfil</a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Configuración</a>
                  <a class="dropdown-item" href="#">
                    <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Registro</a>
                  <div class="dropdown-divider"></div>-->
                  <a class="dropdown-item" (click)="logout()">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>&nbsp;Salir</a></div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
    <footer class="bg-white sticky-footer">
      <div class="container my-auto">
        <div class="text-center my-auto copyright"><span>Comisión Nacional de Energía 2021</span></div>
      </div>
    </footer>
  </div>
  <a *ngIf="currentUser" class="border rounded d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
</div>
