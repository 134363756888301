<div class="container-fluid">
    <div class="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 class="text-dark mb-0">{{title}}</h3>
        <a class="btn btn-primary btn-sm d-none d-sm-inline-block" role="button" routerLink="/proceso/create">
            <i class="fas fa-file fa-sm text-white-50"></i>&nbsp;Crear comité nuevo</a>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">Comités Activos ({{procesos.length}})</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li *ngFor="let proceso of procesos" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-4">
                                    <h6 class="mb-0">{{proceso.nombre}}</h6>
                                    <small>{{proceso.tramites}} solicitudes ingresadas.</small>
                                </div>
                                <div class="col mr-2">
                                    <a routerLink="/proceso/view/{{proceso.id}}" class="btn btn-info">
                                        <i class="fas fa-eye fa-sm text-white-50"></i>&nbsp;Ver</a>&nbsp;
                                    <a routerLink="/proceso/edit/{{proceso.id}}" class="btn btn-primary">
                                        <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;Editar</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">Comités Por Responder ({{procesosDesactivados.length}})</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li *ngFor="let proceso of procesosDesactivados" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-4">
                                    <h6 class="mb-0">{{proceso.nombre}}</h6>
                                    <small>{{proceso.tramites}} solicitudes ingresadas.</small>
                                </div>
                                <div class="col mr-2">
                                    <a routerLink="/proceso/view/{{proceso.id}}" class="btn btn-info">
                                        <i class="fas fa-eye fa-sm text-white-50"></i>&nbsp;Ver</a>&nbsp;
                                    <a routerLink="/proceso/edit/{{proceso.id}}" class="btn btn-primary">
                                        <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;Editar</a>&nbsp;
                                    <a *ngIf="proceso.tramites > 0" routerLink="/proceso/response/{{proceso.id}}"
                                       class="btn btn-warning">
                                        <i class="fas fa-reply fa-sm text-white-50"></i>&nbsp;Responder</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">Comités en Borrador ({{procesosBorrador.length}})</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li *ngFor="let proceso of procesosBorrador" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-4">
                                    <h6 class="mb-0">{{proceso.nombre}}</h6>
                                </div>
                                <div class="col mr-2">
                                    <a routerLink="/proceso/view/{{proceso.id}}" class="btn btn-info">
                                        <i class="fas fa-eye fa-sm text-white-50"></i>&nbsp;Ver</a>&nbsp;
                                    <a routerLink="/proceso/edit/{{proceso.id}}" class="btn btn-primary">
                                        <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;Editar</a>&nbsp;
                                    <a *ngIf="proceso.tramites > 0" routerLink="/proceso/response/{{proceso.id}}"
                                       class="btn btn-warning">
                                        <i class="fas fa-reply fa-sm text-white-50"></i>&nbsp;Responder</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">Comités Antiguos ({{procesosAntiguos.length}})</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li *ngFor="let proceso of procesosAntiguos" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-4">
                                    <h6 class="mb-0">{{proceso.nombre}}</h6>
                                    <small>{{proceso.tramites}} solicitudes respondidas.</small>
                                </div>
                                <div class="col mr-2">
                                    <a routerLink="/proceso/view/{{proceso.id}}" class="btn btn-info">
                                        <i class="fas fa-eye fa-sm text-white-50"></i>&nbsp;Ver</a>&nbsp;
                                    <a routerLink="/proceso/edit/{{proceso.id}}" class="btn btn-primary">
                                        <i class="fas fa-edit fa-sm text-white-50"></i>&nbsp;Editar</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
