import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '@app/_services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './verify.component.html',
})
export class VerifyComponent implements OnInit {
  loading = false;
  code: string;
  id: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap
      .subscribe(
      params => {
        this.id = params.get('id');
        this.code = params.get('code');
      }
    );
    this.authenticationService.verify(this.id, this.code)
      .subscribe(
      data => {
        console.log(data);
      });
  }
}
