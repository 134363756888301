<div class="container-fluid">
    <div class="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 class="text-dark mb-0">{{title}}</h3>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3"></div>
                <div class="card-body py-3">
                    <div *ngIf="loading" class="spinner-border" role="status">
                        <span class="sr-only">Cargando la información...</span>
                    </div>
                    <div *ngIf="proceso.nombre">
                        <form [formGroup]="responseForm" (ngSubmit)="response()">
                            <div class="form-group">
                                <label for="url_res">URL Resolución</label>
                                <input type="text" name="ur_res" id="url_res" formControlName="url_res"
                                       class="form-control" placeholder="Adjunte URL del documento de Resolución Exenta aquí">
                            </div>
                            <input type="hidden" name="cierra_tramite" id="cierra_tramite" value="1">
                            <div class="form-group">
                                <button (click)="response()" id="response" class="btn btn-warning">
                                    <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                                    <i class="fa fa-reply"></i>&nbsp;Responder</button>&nbsp;
                                <a routerLink="/procesos" class="btn btn-success">
                                    <i class="fa fa-backward"></i>&nbsp;Volver</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">Solicitudes ingresadas para este comité:</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-2">
                                    <h6 class="mb-0"><strong>Nombre del Solicitante</strong></h6>
                                </div>
                                <div class="col mr-2">
                                    <h6 class="mb-0"><strong>Fecha ingreso de solicitud</strong></h6>
                                </div>
                            </div>
                        </li>
                        <li *ngFor="let tramite of tramites" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-2">
                                    <h6 class="mb-0">{{tramite.nombre_solicitante}}</h6>
                                </div>
                                <div class="col mr-2">
                                    <h6 class="mb-0">{{tramite.fecha_creacion}}</h6>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
