import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";
import {Tramite} from "@app/_models/tramite";
import {TramiteService} from "@app/_services/tramite.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {first} from "rxjs/operators";

@Component({
    templateUrl: 'response.html'
})
export class ResponseProcesoComponent implements OnInit {
    title = 'Comité ';
    loading = false;
    sumbited = false;
    saving = false;
    proceso: Proceso = new Proceso();
    tramites: Tramite[] = []
    responseForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private procesoService: ProcesoService,
        private tramiteService: TramiteService,
        private _route: ActivatedRoute,
        private router: Router
    ) {
        this.procesoService = procesoService;
        this.tramiteService = tramiteService;
    }

    ngOnInit() {
        this.loading = true;
        this.responseForm = this.formBuilder.group({
            url_res: ['', Validators.required],
            respuesta: ['']
        });
        let id = this._route.snapshot.paramMap.get('id');

        this.procesoService.getById(Number(id))
            .pipe()
            .subscribe(data => {
                this.loading = false;
                if (data instanceof Proceso) {
                    this.proceso = data;
                    this.title = data.nombre;
                }
            });
        this.tramiteService.getAllByProceso(Number(id))
            .pipe()
            .subscribe(data => {
                this.loading = false;
                for (const item in data) {
                    this.tramites.push(data[item]);
                }
            });
    }

    get f() {
        return this.responseForm.controls;
    }

    response() {
        this.sumbited = true;
        this.saving = true;

        if (this.responseForm.invalid){
            this.saving = false;
            console.log('error form');
            return;
        }

        if (confirm("¿Está seguro de responder y cerrar " + this.tramites.length + " solicitudes?")) {
            this.procesoService.response(
                this.proceso.id,
                this.f.url_res.value
            )
                .pipe(first())
                .subscribe(
                    data => {
                        if (data.status === 200)
                            this.saving = false;

                        console.log(data);
                        this.router.navigate(['procesos']);
                    },
                    error => {
                        this.saving = false;
                    });
        }
    }
}
