
export class Proceso {
    constructor(
        public id?: number,
        public nombre?: string,
        public fecha_inicio?: string,
        public fecha_fin?: string,
        public fecha_ext?: string,
        public activado?: boolean,
        public respondido?: boolean,
        public tramites?: number
    ) {
    }
}
