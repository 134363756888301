import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    templateUrl: 'edit.html'
})
export class EditProcesoComponent implements OnInit {
    title = 'Editar ';
    loading = false;
    saving = false;
    submited = false;
    proceso: Proceso = new Proceso();
    editForm: FormGroup;

    constructor(
        private procesoService: ProcesoService,
        private _route: ActivatedRoute,
        private formBuilder: FormBuilder
    ) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
        let id = this._route.snapshot.paramMap.get('id');
        this.editForm = this.formBuilder.group({
            nombre: ['', Validators.required],
            fecha_inicio: ['', Validators.required],
            fecha_fin: ['', Validators.required],
            fecha_ext: [''],
            activado: ['']
        });

        this.procesoService.getById(Number(id))
            .pipe()
            .subscribe(data => {
                this.loading = false;
                if (data instanceof Proceso) {
                    this.proceso = data;
                    this.title = this.title + data.nombre;

                    this.editForm.controls["nombre"].setValue(this.proceso.nombre);
                    this.editForm.controls["fecha_inicio"].setValue(this.proceso.fecha_inicio);
                    this.editForm.controls["fecha_fin"].setValue(this.proceso.fecha_fin);
                    this.editForm.controls["fecha_ext"].setValue(this.proceso.fecha_ext);
                    this.editForm.controls["activado"].setValue(this.proceso.activado);
                }
            });
    }

    get f() {
        return this.editForm.controls;
    }

    onSubmit() {
        this.submited = true;
        this.saving = true;

        if (this.editForm.invalid) {
            this.saving = false;
            return;
        }

        this.proceso.nombre = this.f.nombre.value;
        this.proceso.fecha_inicio = this.f.fecha_inicio.value;
        this.proceso.fecha_fin = this.f.fecha_fin.value;
        this.proceso.fecha_ext = this.f.fecha_ext.value;
        this.proceso.activado = this.f.activado.value;

        this.procesoService.update(this.proceso)
            .pipe()
            .subscribe(
                data => {
                    if (data.status === 200) {
                        this.saving = false;
                        console.log("Datos almacenados");
                    }
                },
                error => {
                    this.saving = false;
                });
    }
}
