// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list-user of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    mailCNE: 'cne.cl',
    apiUrl: 'https://comites.tramites.cne.cl/api'
};

