
export class Tramite {
    constructor (
        public id?: number,
        public simple_id?: number,
        public nombre_solicitante?: string,
        public fecha_creacion?: string
    ) {
    }
}
