import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './_helpers/auth.guard';
import {LoginComponent} from './login/login.component';
import {VerifyComponent} from '@app/verify/verify.component';
import {ViewProcesoComponent} from "@app/procesos/view/view";
import {ListProcesoComponent} from "@app/procesos/list/list";
import {ListTramiteComponent} from "@app/tramites/list/list";
import {ViewTramiteComponent} from "@app/tramites/view/view";
import {CreateProcesoComponent} from "@app/procesos/create/create";
import {EditProcesoComponent} from "@app/procesos/edit/edit";
import {ListConfiguracionComponent} from "@app/configuraciones/list/list";
import {CreateConfiguracionComponent} from "@app/configuraciones/create/create";
import {EditConfiguracionComponent} from "@app/configuraciones/edit/edit";
import {ViewConfiguracionComponent} from "@app/configuraciones/view/view";
import {ResponseProcesoComponent} from "@app/procesos/response/response";


const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'verify/:id/:code', component: VerifyComponent },
    { path: 'proceso/view/:id', component: ViewProcesoComponent, canActivate: [AuthGuard] },
    { path: 'proceso/create', component: CreateProcesoComponent, canActivate: [AuthGuard] },
    { path: 'proceso/edit/:id', component: EditProcesoComponent, canActivate: [AuthGuard] },
    { path: 'proceso/response/:id', component: ResponseProcesoComponent, canActivate: [AuthGuard] },
    { path: 'procesos', component: ListProcesoComponent, canActivate: [AuthGuard] },
    { path: 'tramite/view/:id', component: ViewTramiteComponent, canActivate: [AuthGuard] },
    { path: 'tramites', component: ListTramiteComponent, canActivate: [AuthGuard] },
    { path: 'configuraciones', component: ListConfiguracionComponent, canActivate: [AuthGuard] },
    { path: 'configuracion/create', component: CreateConfiguracionComponent, canActivate: [AuthGuard] },
    { path: 'configuracion/edit/:id', component: EditConfiguracionComponent, canActivate: [AuthGuard] },
    { path: 'configuracion/view/:id', component: ViewConfiguracionComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes);
