import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {appRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule
} from '@angular/common/http';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {HomeComponent} from './home/home.component';
import {VerifyComponent} from '@app/verify/verify.component';
import {DragDropDirective} from '@app/drag-drop.directive';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CdkTableModule} from '@angular/cdk/table';
import {NgxPaginationModule} from 'ngx-pagination';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ViewProcesoComponent} from "@app/procesos/view/view";
import {ListProcesoComponent} from "@app/procesos/list/list";
import {ViewTramiteComponent} from "@app/tramites/view/view";
import {ListTramiteComponent} from "@app/tramites/list/list";
import {CreateProcesoComponent} from "@app/procesos/create/create";
import {EditProcesoComponent} from "@app/procesos/edit/edit";
import {ListConfiguracionComponent} from "@app/configuraciones/list/list";
import {ViewConfiguracionComponent} from "@app/configuraciones/view/view";
import {EditConfiguracionComponent} from "@app/configuraciones/edit/edit";
import {CreateConfiguracionComponent} from "@app/configuraciones/create/create";
import {ResponseProcesoComponent} from "@app/procesos/response/response";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        VerifyComponent,
        ViewProcesoComponent,
        CreateProcesoComponent,
        EditProcesoComponent,
        ListProcesoComponent,
        ViewTramiteComponent,
        ResponseProcesoComponent,
        ListTramiteComponent,
        ListConfiguracionComponent,
        ViewConfiguracionComponent,
        EditConfiguracionComponent,
        CreateConfiguracionComponent,
        DragDropDirective,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        appRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatTableModule,
        CdkTableModule,
        NgxPaginationModule,
        MatInputModule,
        MatSortModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
