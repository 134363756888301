import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";

@Component({
    templateUrl: 'create.html'
})
export class CreateConfiguracionComponent implements OnInit {
    title = 'Crear Comité';
    loading = false;

    constructor(private procesoService: ProcesoService) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
    }
}
