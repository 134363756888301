<div class="container-fluid">
    <div class="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 class="text-dark mb-0">{{title}}</h3>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3"></div>
                <div class="card-body py-3">
                    <div *ngIf="loading" class="spinner-border" role="status">
                        <span class="sr-only">Cargando la información...</span>
                    </div>
                    <div *ngIf="proceso.nombre">
                        <div class="form-group">
                            <label>Nombre:</label>
                            <div class="form-control">{{proceso.nombre}}</div>
                        </div>
                        <div class="form-group">
                            <label>Fecha de Incio:</label>
                            <div class="form-control">{{proceso.fecha_inicio}}</div>
                        </div>
                        <div class="form-group">
                            <label>Fecha de Fin:</label>
                            <div class="form-control">{{proceso.fecha_fin}}</div>
                        </div>
                        <div *ngIf="proceso.fecha_ext" class="form-group">
                            <label>Fecha extensión:</label>
                            <div class="form-control">{{proceso.fecha_ext}}</div>
                        </div>
                        <div class="form-group">
                            <label>Solicitudes ingresadas:</label>
                            <div class="form-control">{{proceso.tramites}}</div>
                        </div>
                        <div class="form-group">
                            <a routerLink="/proceso/edit/{{proceso.id}}" class="btn btn-info">
                                <i class="fa fa-edit"></i>&nbsp;Editar</a>&nbsp;
                            <a *ngIf="proceso.tramites > 0 && proceso.respondido == false"
                               href="/proceso/response/{{proceso.id}}" class="btn btn-warning">
                                <i class="fa fa-reply"></i>&nbsp;Responder solicitudes</a>&nbsp;
                            <a routerLink="/procesos" class="btn btn-success">
                                <i class="fa fa-backward"></i>&nbsp;Volver</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="proceso.tramites > 0" class="row">
        <div class="col-lg-12 mb-6">
            <div class="card shadow mb-6">
                <div class="card-header py-3">Solicitudes ingresadas para este comité:</div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col-lg-2 mr-2">
                                    <h6 class="mb-0"><strong>Id. Trámite</strong></h6>
                                </div>
                                <div class="col-lg-4 mr-3">
                                    <h6 class="mb-0"><strong>Nombre del Solicitante</strong></h6>
                                </div>
                                <div class="col-lg-4 mr-2">
                                    <h6 class="mb-0"><strong>Fecha ingreso de solicitud</strong></h6>
                                </div>
                            </div>
                        </li>
                        <li *ngFor="let tramite of tramites" class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col-lg-2 mr-2">
                                    {{tramite.simple_id}}
                                </div>
                                <div class="col-lg-4 mr-2">
                                    {{tramite.nombre_solicitante}}
                                </div>
                                <div class="col-lg-4 mr-2">
                                    {{tramite.fecha_creacion}}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
