import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";

@Component({
    templateUrl: 'list.html'
})
export class ListProcesoComponent implements OnInit {
    title = 'Listado de Comités';
    loading = false;
    procesos: Proceso[] = [];
    procesosAntiguos: Proceso[] = [];
    procesosDesactivados: Proceso[] = [];
    procesosBorrador: Proceso[] = [];

    constructor(private procesoService: ProcesoService) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
        this.procesoService.getProcesosActivos()
            .pipe()
            .subscribe(data => {
                this.loading = false;
                for (const item in data) {
                    this.procesos.push(data[item]);
                }
            });
        this.procesoService.getProcesosAntiguos()
            .pipe()
            .subscribe(data => {
                for (const item in data) {
                    this.procesosAntiguos.push(data[item]);
                }
            });
        this.procesoService.getProcesosDesactivos()
            .pipe()
            .subscribe(data => {
                for (const item in data) {
                    this.procesosDesactivados.push(data[item]);
                }
            });
        this.procesoService.getProcesosBorrador()
            .pipe()
            .subscribe(data => {
                for (const item in data) {
                    this.procesosBorrador.push(data[item]);
                }
            });
    }
}
