import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '@app/_models/user';


@Injectable({ providedIn: 'root' })
export class UserService {

  protected currentUser: User;

  constructor(
    private http: HttpClient)
  {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')) as User;
  }

  getAll() {
    return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getHome() {
    return this.http.get(`${environment.apiUrl}/home/`);
  }

  getById(id: number) {
    return this.http.get<User>(`${environment.apiUrl}/user/` + id, {});
  }

  register(user: User) {
    if (!user.email && user.username) {
      user.email = user.username;
    } else if (user.email && !user.username) {
      user.username = user.email;
    }

    return this.http.post(`${environment.apiUrl}/auth/register`, user);
  }

  delete(user: User) {
    return this.http.delete(`${environment.apiUrl}/user/${user}`);
  }
}
