<style type="text/css">
  #login-image {
    background-image: url('assets/img/logo-cne-600.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 600px 200px;
  }
</style>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-9 col-lg-12 col-xl-10">
      <div class="card shadow-lg o-hidden border-0 my-5">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-lg-6 d-none d-lg-flex">
              <div class="flex-grow-1 bg-login-image" id="login-image"></div>
            </div>
            <div class="col-lg-6">
              <div class="p-5">
                <div class="text-center">
                  <h4 id="app-title" class="text-dark mb-4">Gestionador de Comités Consultivos</h4>
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <label for="email">Correo</label>
                  <input type="text" formControlName="email" id="email"
                         class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">El correo es requerido</div>
                  </div>
                  <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" formControlName="password" id="password"
                           class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required">La contraseña es requerida</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox small">
                      <div class="form-check">
                        <input type="checkbox"
                               class="form-check-input custom-control-input"
                               formControlName="rememberMe"
                               id="rememberme"/>
                        <label class="form-check-label custom-control-label" for="rememberme">Recuérdame</label>
                      </div>
                    </div>
                  </div>
                  <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Ingresar
                  </button>
                  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
