import {Component, OnInit} from '@angular/core';
import {User} from '@app/_models/user';
import {UserService} from '@app/_services/user.service';
import {Proceso} from "@app/_models/proceso";


@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {
    title = 'Inicio';
    loading = false;
    users: User[];
    procesosCount = 0;
    tramitesCount = 0;

    constructor(private userService: UserService) { }

    ngOnInit() {
        this.loading = false;
        this.userService.getHome()
            .pipe()
            .subscribe(data => {
                this.loading = false;
                this.procesosCount = data['procesos'];
                this.tramitesCount = data['tramites']
            });
    }
}
