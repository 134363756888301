import { Component } from '@angular/core';
import {User} from './_models/user';
import {Router} from '@angular/router';
import {AuthenticationService} from './_services/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    currentUser: User;
    title: string;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.title = 'Gestionador de procesos SIMPLE';
    }

    logout() {
        this.authenticationService.logout().subscribe(
            results => this.router.navigate(['/login'])
        );
    }

}
