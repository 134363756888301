import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";

@Component({
    templateUrl: 'list.html'
})
export class ListTramiteComponent implements OnInit {
    title = 'Listado de Comités';
    loading = false;

    constructor(private procesoService: ProcesoService) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
    }
}
