import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
    templateUrl: 'create.html'
})
export class CreateProcesoComponent implements OnInit {
    title = 'Crear Comité';
    loading = false;
    submited = false;
    proceso: Proceso = new Proceso();
    createForm: FormGroup

    constructor(
        private procesoService: ProcesoService,
        private formBuilder: FormBuilder,
        private router: Router
    ) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
        this.createForm = this.formBuilder.group({
            nombre: ['', Validators.required],
            fecha_inicio: ['', Validators.required],
            fecha_fin: ['', Validators.required],
            fecha_ext: ['', Validators.required],
            activado: ['']
        });
        this.loading = false;
    }

    get f() {
        return this.createForm.controls;
    }

    onSubmit() {
        this.submited = true;
        if (this.createForm.invalid) {
            this.submited = false;
            return;
        }

        this.proceso.nombre = this.f.nombre.value;
        this.proceso.fecha_inicio = this.f.fecha_inicio.value;
        this.proceso.fecha_fin = this.f.fecha_fin.value;
        this.proceso.fecha_ext = this.f.fecha_ext.value;
        this.proceso.activado = this.f.activado.value;

        this.procesoService.create(this.proceso)
            .pipe()
            .subscribe(
                data => {
                    if (data.status === 200) {
                        this.submited = false;
                        console.log("Datos almacenados");
                        let proceso_id = data['data'].id;
                        this.router.navigate(['proceso/view/' + proceso_id])
                    }
                },
                error => {
                    this.submited = false;
                });
    }
}
/*
let res = data['data'];
                return new Proceso(
                    res.id,
                    res.nombre,
                    res.fecha_inicio,
                    res.fecha_fin,
                    res.fecha_ext,
                    res.activado,
                    res.tramites
 */
