import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {Tramite} from "@app/_models/tramite";
import {ProcesoService} from "@app/_services/proceso.service";
import {TramiteService} from "@app/_services/tramite.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    templateUrl: 'view.html'
})
export class ViewProcesoComponent implements OnInit {
    title = 'Comité ';
    loading = false;
    proceso: Proceso = new Proceso();
    tramites: Tramite[] = []

    constructor(
        private procesoService: ProcesoService,
        private tramiteService: TramiteService,
        private _route: ActivatedRoute
    ) {
        this.procesoService = procesoService;
        this.tramiteService = tramiteService;
    }

    ngOnInit() {
        this.loading = true;
        let id = this._route.snapshot.paramMap.get('id');
        this.procesoService.getById(Number(id))
            .pipe()
            .subscribe(data => {
                this.loading = false;
                if (data instanceof Proceso) {
                    this.proceso = data;
                    this.title = data.nombre;
                }
            });
        this.tramiteService.getAllByProceso(Number(id))
            .pipe()
            .subscribe(data => {
                this.loading = false;
                for (const item in data) {
                    this.tramites.push(data[item]);
                }
            });
    }
}
