<div class="container-fluid">
    <div class="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 class="text-dark mb-0">{{title}}</h3>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3"></div>
                <div class="card-body py-3">
                    <form [formGroup]="createForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="nombre">Nombre:</label>
                            <input type="text" name="nombre" formControlName="nombre"
                                   id="nombre" class="form-control" value="{{proceso.nombre}}"/>
                        </div>
                        <div class="form-group">
                            <label for="fecha_inicio">Fecha de Incio:</label>
                            <input type="datetime-local" name="fecha_inicio" formControlName="fecha_inicio"
                                   id="fecha_inicio" class="form-control" value="{{proceso.fecha_inicio}}">
                        </div>
                        <div class="form-group">
                            <label for="fecha_fin">Fecha de Fin:</label>
                            <input type="datetime-local" name="fecha_fin" formControlName="fecha_fin"
                                   id="fecha_fin" class="form-control" value="{{proceso.fecha_fin}}">
                        </div>
                        <div class="form-group">
                            <label for="fecha_ext">Fecha de respuesta CNE:</label>
                            <input type="datetime-local" name="fecha_ext" formControlName="fecha_ext"
                                   id="fecha_ext" class="form-control" value="{{proceso.fecha_ext}}">
                        </div>
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" id="activar"
                                       type="checkbox" name="activado" value="1" formControlName="activado"
                                       [(ngModel)]="proceso.activado">
                                <label class="form-check-label" for="activar">Activar comité</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loading" class="btn btn-info">
                                <span *ngIf="submited" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                                <i class="fa fa-save"></i>&nbsp;
                                Guardar
                            </button>&nbsp;
                            <a routerLink="/proceso/view/{{proceso.id}}" class="btn btn-success">
                                <i class="fa fa-backward"></i>&nbsp;Volver
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
