<div class="container-fluid">
    <div class="d-sm-flex justify-content-between align-items-center mb-4">
        <h3 class="text-dark mb-0">{{title}}</h3>
        <a class="btn btn-primary btn-sm d-none d-sm-inline-block" role="button" href="#">
            <i class="fas fa-download fa-sm text-white-50"></i>&nbsp;Generate Report</a>
    </div>
    <div class="row">
        <div class="col-lg-12 mb-4">
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="text-primary font-weight-bold m-0">Gestionador de Comités Consultivos</h6>
                </div>
                <div class="card-body py-3">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row align-items-center no-gutters">
                                <div class="col mr-6">
                                    <h6 class="mb-0">Actualmente tenemos <strong>{{procesosCount}}</strong> comités activos, con <strong>{{tramitesCount}}</strong> solicitudes ingresadas.</h6>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
