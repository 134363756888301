import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '@app/_models/user';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Tramite} from "@app/_models/tramite";

@Injectable({providedIn: 'root'})
export class TramiteService {

    protected currentUser: User;

    constructor(
        private http: HttpClient)
    {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser')) as User;
    }

    getAll() {

    }

    getAllByProceso(id: number): Observable<Tramite[]> {
        return this.http.get<Tramite[]>(`${environment.apiUrl}/tramites/` + id)
            .pipe(map(res => {
                let tramites: Tramite[] = [];
                for (const item in res) {
                    let tramite = res[item];
                    tramites.push(new Tramite(
                        tramite.id,
                        tramite.simple_id,
                        tramite.nombre_solicitante,
                        tramite.fecha_creacion
                    ));
                }
                return tramites;
            }));
    }
}
