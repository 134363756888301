import {Component, OnInit} from '@angular/core';
import {Proceso} from "@app/_models/proceso";
import {ProcesoService} from "@app/_services/proceso.service";

@Component({
    templateUrl: 'view.html'
})
export class ViewConfiguracionComponent implements OnInit {
    title = 'Comité ';
    loading = false;
    procesos: Proceso[] = [];

    constructor(private procesoService: ProcesoService) {
        this.procesoService = procesoService;
    }

    ngOnInit() {
        this.loading = true;
    }
}
