import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { AuthenticationService } from '@app/_services/authentication.service';
import { Observable } from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.api_token) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.api_token}`
                }
            });
        }

        return next.handle(req);
    }
}
